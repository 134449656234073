import { Section, Tab, TabProps, Tabs } from '@matillion/component-library'
import { useLocation, useNavigate } from 'react-router-dom'
import classes from './NavigationTabs.module.scss'
import { useCanAccessMETL } from 'hooks/useCanAccessMETL'

interface TabChangeProps {
  value: number
  tab: TabProps
}

const TabUrls = ['/', '/instances']

export const getIndex = (name: string) => {
  const index = TabUrls.indexOf(name)
  return index > -1 ? index : 0
}

const NavigationTabs = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { canAccessMETL } = useCanAccessMETL()
  const handleTabChange = (tabChange: TabChangeProps) => {
    navigate(TabUrls[tabChange.value])
  }
  return (
    <Section size={12}>
      <Tabs
        onChange={handleTabChange}
        listClassName={classes.NavigationTabs}
        initialIndex={getIndex(pathname)}
      >
        <Tab title="Data Productivity Cloud" />
        {canAccessMETL ? <Tab title="Matillion ETL" /> : <></>}
      </Tabs>
    </Section>
  )
}

export default NavigationTabs
